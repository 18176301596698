<template>
  <v-container v-if="apiLoaded">
    <h1>
      <span>Проводка {{ this.wData.id }} </span>
      <div>
        <router-link to="#">
          <v-btn outlined color="error">Запрос на удаление</v-btn>
        </router-link>
        <router-link :to="`/wiringEdit/${this.wData.id}`">
          <v-btn>Редактировать</v-btn>
        </router-link>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              outlined
              style="min-width: 0; width: 48px"
            >
              <v-icon color="#3577BE">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>Разбить сумму</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Клонировать</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title
                >Связать с фактической проводкой</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title
                >Привязать к родительской проводке</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title
                >Привязать к дочерней проводке</v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Создать дочернюю проводку</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>История изменений</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </h1>

    <section class="form-wrap">
      <v-tabs>
        <v-tab>Информация</v-tab>
        <v-tab>Связанные проводки</v-tab>
        <v-tab>Данные из 1С</v-tab>
        <v-tab>Права доступа</v-tab>

        <v-tab-item>
          <table class="double">
            <tr>
              <th>ID Проводки</th>
              <td>{{ this.wData.id ? this.wData.id : '-' }}</td>
            </tr>
            <tr>
              <th>Сумма</th>
              <td>
                {{
                  this.wData.data.sum
                    ? (+this.wData.data.sum).toLocaleString('ru')
                    : '-'
                }}
                руб.
              </td>
            </tr>
            <!-- <tr>
              <th>Дата изменения??</th>
              <td>10.12.2020??</td>
            </tr> -->
            <tr>
              <th>План / Факт</th>
              <td>
                {{
                  this.wData.data.wiringType == 1
                    ? 'Планируемая'
                    : 'Фактическая'
                }}
              </td>
            </tr>
            <tr>
              <th>Нал / Безнал</th>
              <td>
                {{
                  this.wData.data.paymentMethod.text
                    ? this.wData.data.paymentMethod.text
                    : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>Дата оплаты</th>
              <td>
                {{
                  this.wData.data.paymentDate
                    ? new Date(this.wData.data.paymentDate).toLocaleDateString(
                        'ru-RU'
                      )
                    : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>Ответственный</th>
              <td>
                <a href="#">{{
                  this.wData.data.expert.fullName
                    ? this.wData.data.expert.fullName
                    : '-'
                }}</a>
              </td>
            </tr>
            <tr>
              <th>Организация</th>
              <td>
                <a href="#">{{
                  this.wData.data.organization.shortName
                    ? this.wData.data.organization.shortName
                    : '-'
                }}</a>
              </td>
            </tr>
            <tr>
              <th>Доход / Расход</th>
              <td>{{ wiringTypeDirDict[this.wData.data.wiringTypeDir] }}</td>
            </tr>
            <tr>
              <th>Фонд</th>
              <td>
                {{
                  this.wData.data.funds.fund ? this.wData.data.funds.fund : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>Статья</th>
              <td>
                {{
                  this.wData.data.article.article
                    ? this.wData.data.article.article
                    : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>Контрагент</th>
              <td>
                <a href="#">{{
                  this.wData.data.contractor.shortName
                    ? this.wData.data.contractor.shortName
                    : this.wData.data.contractor.fullName
                }}</a>
              </td>
            </tr>
            <tr>
              <th>Год по бюджету</th>
              <td>
                {{
                  this.wData.data.budgetYear ? this.wData.data.budgetYear : '-'
                }}
              </td>
            </tr>
            <tr>
              <th>Проект</th>
              <td>
                <a href="#">{{
                  this.wData.data.project.name
                    ? this.wData.data.project.name
                    : '-'
                }}</a>
              </td>
            </tr>
            <tr>
              <th>Договор</th>
              <td>
                <a href="#">
                  {{
                    this.wData.data.contractId.contractNumber
                      ? this.wData.data.contractId.contractNumber
                      : '-'
                  }}
                  <!-- Договор 01/25/62-20-2 от 20.09.2020 -->
                </a>
              </td>
            </tr>
            <tr>
              <th>Номер счета</th>
              <td>
                <a href="#">{{
                  this.wData.data.accountNumber
                    ? this.wData.data.accountNumber
                    : '-'
                }}</a>
              </td>
            </tr>
            <!-- <tr>
              <th>Наименование вклада??</th>
              <td>–??</td>
            </tr> -->
            <!-- <tr>
              <th>Автор записи??</th>
              <td><a href="#">Краснов С.М.??</a></td>
            </tr> -->
            <tr>
              <th>Примечание</th>
              <td>
                {{ this.wData.data.comment ? this.wData.data.comment : '–' }}
              </td>
            </tr>
          </table>
        </v-tab-item>

        <v-tab-item>
          <table class="tripple">
            <tr>
              <td>
                Фактические проводки
                <span>Связанные с плановой</span>
              </td>
              <td>
                <v-chip class="ma-1" close color="#E3EBFC"> 1832845754 </v-chip>
              </td>
            </tr>
            <tr>
              <td>
                Плановые проводки
                <span>Связанные с фактической</span>
              </td>
              <td>
                <v-chip class="ma-1" close color="#E3EBFC"> 1832845754 </v-chip>
              </td>
            </tr>
            <tr>
              <td>Родительская проводка</td>
              <td></td>
            </tr>
            <tr>
              <td>Дочерние проводки</td>
              <td></td>
            </tr>
          </table>
        </v-tab-item>

        <v-tab-item>
          <table class="double">
            <tr>
              <th>Период</th>
              <td>19.11.2020</td>
            </tr>
            <tr>
              <th>Документ</th>
              <td>
                Списание с расчетного счета НИС00000109 от 29.11.2020 0:00:00
                Оплата за выполненные работы по договору 01/25/62-20-2 от
                20.09.2020 «Подготовка предложений по созданию сервиса «бизнес
                под ключ»...» по вх.д.86 от
              </td>
            </tr>
            <tr>
              <th>Аналитика Дт</th>
              <td>
                КИБЕРГРУППА Договор 01/25/62-20-2 от 20.09.2020 Поступление
                товаров и услуг НИС00000025 от 22.10.2020 11:47:30
              </td>
            </tr>
            <tr>
              <th>Аналитика Кт</th>
              <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
            </tr>
            <tr>
              <th>Дебет – счет</th>
              <td>60.01</td>
            </tr>
            <tr>
              <th>Дебет – сумма</th>
              <td>1 000 000,00 руб.</td>
            </tr>
            <tr>
              <th>Кредит – счет</th>
              <td>51</td>
            </tr>
            <tr>
              <th>Кредит – сумма</th>
              <td>1 000 000,00 руб.</td>
            </tr>
            <tr>
              <th>Дебет – счет</th>
              <td>60.01</td>
            </tr>
          </table>
        </v-tab-item>

        <v-tab-item>
          <table class="tripple">
            <tr>
              <td>
                <span>Просмотр</span>
              </td>
              <td>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Сотрудники ГК НИСИПП
                </v-chip>
              </td>
            </tr>
            <tr>
              <td>
                <span>Редактирование и удаление</span>
              </td>
              <td>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Краснов С.М.
                </v-chip>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Шевернев Ю.А.
                </v-chip>
              </td>
            </tr>
            <tr>
              <td>
                <span>Уведомление в ЛК</span>
              </td>
              <td>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Краснов С.М.
                </v-chip>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Шевернев Ю.А.
                </v-chip>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Старожилец Л.М.
                </v-chip>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Шеховцов А.О.
                </v-chip>
                <v-chip class="ma-1" close color="#E3EBFC">
                  Литвак Е.Г.
                </v-chip>
              </td>
            </tr>
          </table>
        </v-tab-item>
      </v-tabs>
    </section>
  </v-container>
</template>

<script>
import { wGetById, wDecOne } from '@/modules/Wirings';
import { wiringTypeDirDict } from '@/components/register/WireDict';

export default {
  name: 'WiringComponent',
  props: {},
  components: {},
  data() {
    return {
      apiLoaded: false,
      wiringTypeDirDict,
    };
  },
  methods: {},
  async beforeMount() {
    this.id = this.$route.params.id;
    if (this.id) {
      try {
        const encrypted = await wGetById(this.id);
        if (encrypted) {
          this.wData = await wDecOne(encrypted);
          console.log(this.id, this.wData);
        }
        this.apiLoaded = true;
      } catch {
        console.log('Возможно передан ошибочный id');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  padding-right: 0;
  table {
    width: 100%;
    th {
      border-top: none;
    }
    td:first-child {
      padding-left: 0;
    }
    td:last-child {
      padding-right: 0;
    }
    th:first-child {
      padding-left: 0;
    }
    th:last-child {
      padding-right: 0;
    }
    &.double {
      td {
        width: 50%;
      }
    }
    &.tripple {
      td:first-child {
        font-size: 13px;
        span {
          display: block;
          color: #77809a;
        }
      }
      td:nth-child(2) {
        text-align: right;
      }
    }
  }
}
</style>
